const navItems = [
  {
    label: {
      bg: "Начало",
      en: "Home",
    },
    path: "/",
    name: "home",
    page: "HomePage",
  },
  {
    label: {
      bg: "За нас",
      en: "About us",
    },
    path: "/about",
    name: "about",
    page: "AboutPage",
  },
  {
    label: {
      bg: "Общи събрания",
      en: "General meetings",
    },
    path: "/general-meetings/:slug",
    name: "general-meetings",
    page: "GeneralMeetings",
    children: [
      {
        path: "/general-meetings/invitation",
        label: {
          bg: "Покана",
          en: "Invitation",
        },
      },
      {
        path: "/general-meetings/protocols",
        label: {
          bg: "Протоколи",
          en: "Protocols",
        },
      },
      {
        path: "/general-meetings/materials",
        label: {
          bg: "Материали",
          en: "Materials",
        },
      },
    ],
  },
  {
    label: {
      bg: "Контакт",
      en: "Contact",
    },
    path: "/contact",
    name: "contact",
    page: "ContactPage",
  },
];

export default navItems;
