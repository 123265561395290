const smallWidth = 768;
const mediumWidth = 1024;
const xLargeWidth = 1280;

const deviceType = {
  mobile: `(max-width: ${smallWidth - 1}px)`,
  tablet: `(min-width: ${smallWidth}px) and (max-width: ${mediumWidth - 1}px)`,
  "small-desktop": `(min-width: ${mediumWidth}px)`,
  desktop: `(min-width: ${xLargeWidth}px)`,
};

export const screenSize = (type) => window.matchMedia(deviceType[type]).matches;

/**
 * Touch Detection
 *
 * @returns {object} Keys(devices/OS):values(boolean)
 */
export const isTouch = () => {
  const test = {
    android: /Android/i.test(navigator.userAgent),
    iPhone: /iPhone|iPod/i.test(navigator.userAgent),
    iPad:
      /iPad/i.test(navigator.userAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1),
    blackBerry: /BlackBerry/i.test(navigator.userAgent),
    ieMobile: /IEMobile/i.test(navigator.userAgent),
    webOS: /webOS/i.test(navigator.userAgent),
    operaMini: /Opera Mini/i.test(navigator.userAgent),
  };

  test.iOS = test.iPhone || test.iPad;
  test.any = Object.values(test).some((e) => e);

  return test;
};
