<template>
  <div class="global-navigation" :class="{ shadow }">
    <div class="global-navigation__wrapper container">
      <a class="global-navigation__logo-link" href="/">
        <img
          ref="logo"
          class="global-navigation__logo"
          :src="require(`@/assets/images/${websiteInfo.logo.normal}`)"
          :alt="logoAlt"
        />
      </a>
      <nav ref="nav">
        <button
          class="button global-navigation__toggler"
          v-if="toggler"
          @click="toggleNavigation"
        >
          <span class="material-symbols-rounded">menu</span>
        </button>
        <div
          class="global-navigation__list-wrapper"
          :class="{ 'global-navigation__list-wrapper--show': showNavigation }"
        >
          <button
            class="button button-light-blue global-navigation__close"
            v-if="showNavigation"
            @click="toggleNavigation"
          >
            <span class="material-symbols-rounded">close</span>
          </button>
          <ul class="global-navigation__list">
            <li
              class="global-navigation__item"
              v-for="item in navItems"
              v-bind:key="item"
            >
              <router-link
                v-if="!item.children"
                :to="item.path"
                @click="toggleNavigation"
              >
                {{ item.label[this.lang] }}
              </router-link>
              <div class="global-navigation__secondary-list-wrapper" v-else>
                <button
                  class="button global-navigation__secondary-dropdown"
                  @click="(e) => toggleDropdown(e)"
                >
                  {{ item.label[this.lang] }}
                  <span class="material-symbols-outlined">arrow_drop_down</span>
                </button>
                <ul class="global-navigation__secondary-list">
                  <li
                    class="global-navigation__item"
                    v-for="childItem in item.children"
                    v-bind:key="childItem"
                  >
                    <router-link
                      :to="childItem.path"
                      @click="
                        toggleNavigation();
                        toggleDropdown(null);
                      "
                    >
                      {{ childItem.label[this.lang] }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { websiteInfo } from "@/composable/websiteData";
import navItems from "@/composable/globalNavigationItems";
export default {
  name: "GlobalNavigation",
  data() {
    return {
      websiteInfo,
      navItems,
      toggler: false,
      shadow: false,
      showNavigation: false,
      showDropdown: false,
      logoAlt: `${websiteInfo.name[this.lang]} ${this.ci18n.logo[this.lang]}`,
    };
  },
  methods: {
    toggleNavigation() {
      if (!this.screenSize("small-desktop")) {
        this.showNavigation = !this.showNavigation;
        this.scrollLock(this.showNavigation);
      }
    },
    toggleDropdown(event) {
      const className = "global-navigation__secondary-dropdown";
      const dropdowns = document.querySelectorAll(`.${className}`);
      dropdowns.forEach((dropdown) => {
        event?.stopPropagation();
        const force =
          event &&
          event.target === dropdown &&
          !event.target.classList.contains(`${className}--active`);
        dropdown.classList.toggle(`${className}--active`, force);
      });
    },
  },
  mounted() {
    this.toggler = !this.screenSize("small-desktop");
    window.addEventListener("resize", () => {
      this.toggler = !this.screenSize("small-desktop");
    });
    document.addEventListener("scroll", () => {
      this.shadow = window.scrollY > 0;
    });
    document.addEventListener("click", this.toggleDropdown.bind(this));
  },
};
</script>

<style lang="scss">
.global-navigation {
  position: sticky;
  top: 0;
  background: var(--body-bg);
  padding: 1.5rem 0;
  z-index: 1060;

  .global-navigation__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .global-navigation__logo-link {
    width: 3.2rem;
    overflow: hidden;

    @media screen and (min-width: 768px) {
      width: auto;
      overflow: auto;
    }

    .global-navigation__logo {
      height: 3rem;

      @media screen and (min-width: 768px) {
        height: 3rem;
      }
    }
  }

  nav {
    .global-navigation__toggler {
      background: white;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;

      span {
        font-size: 2rem;
      }

      + .global-navigation__list-wrapper {
        position: fixed;
        top: 1.5rem;
        left: 1.5rem;
        width: calc(100% - 3rem);
        height: calc(100% - 3rem);
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        border-radius: var(--border-radius);
        -webkit-box-shadow: var(--big-shadow);
        -moz-box-shadow: var(--big-shadow);
        box-shadow: var(--big-shadow);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease-in-out;
        pointer-events: none;

        &.global-navigation__list-wrapper--show {
          opacity: 1;
          visibility: visible;
          pointer-events: all;
        }

        .global-navigation__close {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          width: 3rem;
          height: 3rem;
          border-radius: 50%;

          span {
            font-size: 2rem;
            color: inherit;
          }
        }

        .global-navigation__list {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1.5rem;
          padding: 0;
        }
      }
    }

    .global-navigation__list,
    .global-navigation__secondary-list {
      list-style: none;
      display: flex;
    }

    a,
    .global-navigation__secondary-dropdown {
      font-weight: bold;
      color: var(--dark-blue);
      text-decoration: none;
      padding: 1rem;
      white-space: nowrap;

      &:hover {
        opacity: 0.75;
      }

      &.router-link-exact-active {
        color: var(--light-blue);
      }
    }

    .global-navigation__secondary-list-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    .global-navigation__secondary-list {
      display: none;
      position: relative;
      padding-left: 0;
      margin-top: 1rem;
      border-radius: var(--border-radius);
      background: var(--body-bg);
      width: 30rem;
      max-width: calc(100vw - 6rem);

      @media screen and (min-width: 767px) {
        width: auto;
        min-width: 20rem;

        &::before {
          content: "";
          border: 0.5rem solid;
          border-color: transparent transparent var(--body-bg);
          position: absolute;
          top: -1rem;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .global-navigation__item a {
        display: block;
        text-align: center;
      }
    }

    .global-navigation__secondary-dropdown {
      padding-top: 0;
      padding-bottom: 0;
      font-size: 1rem;

      span {
        pointer-events: none;
      }

      &.global-navigation__secondary-dropdown--active {
        span {
          transform: rotate(180deg);
        }

        + .global-navigation__secondary-list {
          display: block;

          @media screen and (min-width: 767px) {
            position: absolute;
            top: 1rem;
            left: 50%;
            transform: translateX(-50%);
            border: 1px solid var(--border-color);
            -webkit-box-shadow: var(--small-shadow);
            -moz-box-shadow: var(--small-shadow);
            box-shadow: var(--small-shadow);
          }
        }
      }
    }
  }
}
</style>
