<template>
  <button class="button go-top" v-if="show" @click="scrollTop">
    <span class="material-symbols-outlined">vertical_align_top</span>
  </button>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.show = document.documentElement.scrollTop > 200;
    });
  },
};
</script>

<style lang="scss">
.button.go-top {
  position: fixed;
  bottom: 5rem;
  right: 0;
  background: var(--hell-yellow);
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  width: 3rem;
  height: 3rem;

  span {
    font-size: 2rem;
    font-weight: 900;
  }
}
</style>
