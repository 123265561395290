const footerNavItems = {
  legal: {
    title: {
      bg: "Юридически",
      en: "Legal & Compliance",
    },
    items: [
      {
        label: {
          bg: "Политика за поверителност",
          en: "Privacy Policy",
        },
        path: "/privacy-policy",
        name: "privacy-policy",
        page: "PrivacyPolicyPage",
      },
      {
        label: {
          bg: "Условия за ползване",
          en: "Terms & Conditions",
        },
        path: "/terms-and-conditions",
        name: "terms-and-conditions",
        page: "TermsAndConditionsPage",
      },
      {
        label: {
          bg: "Бисквитки (Cookies)",
          en: "Cookies",
        },
        path: "/cookies",
        name: "cookies",
        page: "CookiesPage",
      },
    ],
  },
};

export default footerNavItems;
