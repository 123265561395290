<template>
  <div v-if="true">
    <GlobalNavigation />
    <div class="main container">
      <router-view />
      <FooterSection />
    </div>
    <go-top-button />
    <cookie-consent-box />
    <pop-over />
  </div>
  <LoadingSpinner v-else />
</template>

<script>
// @ is an alias to /src
import GlobalNavigation from "@/components/GlobalNavigation.vue";
import FooterSection from "@/components/FooterSection.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import GoTopButton from "@/components/GoTopButton.vue";
import CookieConsentBox from "@/components/CookieConsentBox.vue";
import PopOver from "@/components/PopOver.vue";

export default {
  components: {
    GlobalNavigation,
    FooterSection,
    LoadingSpinner,
    GoTopButton,
    CookieConsentBox,
    PopOver,
  },
};
</script>

<style lang="scss">
:root {
  --body-bg: #f3f9fb;
  --dark-blue: #141e50;
  --light-blue: #00a0e3;
  --hell-yellow: #ffe22e;
  --border-radius: 0.5rem;
  --border-color: #dce0e5;
  --small-shadow: 0 0 0.5rem 0.125rem rgba(0, 0, 0, 0.3);
  --big-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.3);
}

@font-face {
  font-family: "futurabook";
  src: url("./assets/fonts/futurabook/futurabook.eot");
  src: local("futurabook"),
    url("./assets/fonts/futurabook/futurabook.woff") format("woff"),
    url("./assets/fonts/futurabook/futurabook.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

*,
::before,
::after {
  font-family: futurabook, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: black;
}

html {
  scroll-behavior: smooth;
}

.button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-light-blue {
  background: var(--light-blue);
  color: white;

  &:hover {
    background: var(--dark-blue);
  }
}

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: var(--body-bg);
}

.container {
  padding: 0 1.5rem;
  margin: auto;
  max-width: 1600px;

  @media screen and (min-width: 768px) {
    padding: 0 3rem;
  }
}

.heading-gray {
  color: #848484;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
</style>
