<template>
  <div class="loading-spinner__wrapper">
    <div class="loading-spinner"></div>
  </div>
</template>

<style scoped lang="scss">
$size: 4rem;

.loading-spinner__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1090;
}

.loading-spinner:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: $size;
  height: $size;
  box-sizing: border-box;
  transform: rotate(45deg);
  border-radius: 50%;
  border-style: solid;
  border-color: transparent;
  border-left-color: var(--light-blue);
  border-right-color: var(--light-blue);
  animation-name: zoom-in-out, spin;
  animation-duration: 5.2s, 1.3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes zoom-in-out {
  0%,
  100%,
  5%,
  95% {
    border-width: 2rem;
  }

  20%,
  80% {
    border-width: 0.4rem;
  }
}

@keyframes spin {
  from {
    transform: rotate(45deg);
  }

  to {
    transform: rotate(405deg);
  }
}
</style>
