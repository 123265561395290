export const ci18n = Object.freeze({
  logo: {
    bg: "лого",
    en: "logo",
  },
  learnMore: {
    bg: "Научете повече",
    en: "Learn more",
  },
  gm: {
    bg: "за общо събрание",
    en: "for general meeting",
  },
  about: {
    bg: "за",
    en: "about",
  },
});
