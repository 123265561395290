<template>
  <div class="footer-copy">
    <div class="footer-copyright">{{ copyRight() }}</div>
    <a class="footer-webmaster" :href="webmaster.url" target="_new">
      {{ webmaster.text[lang] }}:
      <img
        :src="require(`@/assets/images/${webmaster.logo}`)"
        :alt="webmaster.name"
      />
    </a>
  </div>
</template>

<script>
export default {
  props: ["webmaster", "websiteInfo"],
  data() {
    return {
      lang: this.lang,
      fullYear: new Date().getFullYear(),
    };
  },
  methods: {
    copyRight() {
      return `©${this.fullYear} ${this.websiteInfo.name["bg"]}`;
    },
  },
};
</script>

<style lang="scss">
.footer-copy {
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-direction: column;
  padding: 1rem 0;
  font-size: 0.75rem;
  border-top: 1px solid #dce0e5;

  @media screen and (min-width: 767px) {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-webmaster {
    display: flex;
    text-decoration: none;
    color: black;
    margin-right: -0.75rem;
  }
}
</style>
