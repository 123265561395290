export const webmaster = Object.freeze({
  text: {
    bg: "Уеб дизайн и програмиране",
    en: "Web design and Development",
  },
  url: "https://www.yweb.dev",
  name: "yweb.dev",
  logo: "yweb-dev-logo-sm.svg",
});

export const websiteInfo = Object.freeze({
  name: {
    bg: "Булгартабак Холдинг АД",
    en: "Bulgartabac Holding AD",
  },
  logo: {
    normal: "bulgartabac_logo_long.svg",
    big: "bulgartabac_logo_large.svg",
  },
});
