<template>
  <div class="footer-navigation__list-wrapper">
    <h4 class="footer-navigation__title" v-if="title">{{ title }}</h4>
    <ul class="footer-navigation__list">
      <li
        class="footer-navigation__item"
        v-for="item in items"
        v-bind:key="item"
      >
        <router-link :to="item.path" class="footer-navigation__item-link">
          {{ item.label[this.lang] }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["title", "items"],
};
</script>

<style lang="scss">
.footer-navigation__title {
  text-transform: uppercase;
}

.footer-navigation__list {
  list-style: none;
  padding: 0;

  .footer-navigation__item {
    margin: 1rem 0;

    .footer-navigation__item-link:not(:hover) {
      text-decoration: none;
    }
  }
}
</style>
