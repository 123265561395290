import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { scrollLock } from "@/utils/scrollLock";
import { screenSize, isTouch } from "@/utils/device";
import { websiteInfo } from "@/composable/websiteData";
import { ci18n } from "@/composable/ci18n";

const app = createApp(App);
app.config.globalProperties.lang = document.documentElement.lang;
app.config.globalProperties.ci18n = ci18n;
app
  .mixin({
    methods: {
      scrollLock,
      screenSize,
      isTouch,
    },
    watch: {
      $route(to) {
        document.title =
          `${to.meta.title[this.lang]} | ${websiteInfo.name[this.lang]}` ||
          websiteInfo.name[this.lang];
      },
    },
  })
  .use(router)
  .mount("#app");
