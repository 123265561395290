<template>
  <div class="footer-content">
    <div class="footer-logo">
      <img
        :src="require(`@/assets/images/${websiteInfo.logo.big}`)"
        :alt="logoAlt"
      />
      <p>Some text here</p>
    </div>
    <FooterItems :title="legal.title[this.lang]" :items="legal.items" />
    <FooterItems :title="legal.title[this.lang]" :items="legal.items" />
  </div>
</template>

<script>
import FooterItems from "@/components/footer/FooterItems.vue";
import footerNavigationItems from "@/composable/footerNavigationItems";
export default {
  props: ["websiteInfo"],
  components: {
    FooterItems,
  },
  data() {
    return {
      logoAlt: `${this.websiteInfo.name[this.lang]} ${
        this.ci18n.logo[this.lang]
      }`,
      legal: footerNavigationItems.legal,
    };
  },
};
</script>

<style lang="scss">
.footer-content {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  .footer-logo {
    img {
      width: 100%;
    }
  }

  @media screen and (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
  }
}
</style>
