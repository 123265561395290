<template>
  <footer>
    <FooterContent :websiteInfo="websiteInfo" />
    <FooterCopy :webmaster="webmaster" :websiteInfo="websiteInfo" />
  </footer>
</template>

<script>
import { webmaster, websiteInfo } from "@/composable/websiteData";
import FooterCopy from "@/components/footer/FooterCopy";
import FooterContent from "@/components/footer/FooterContent";

export default {
  components: {
    FooterCopy,
    FooterContent,
  },
  data() {
    return {
      webmaster,
      websiteInfo,
    };
  },
};
</script>

<style lang="scss"></style>
