<template>
  <div class="popover">
    <div class="popover__wrapper" ref="wrapper">
      <div class="popover__heading">
        <h3 class="popover__title" ref="title"></h3>
        <button class="button popover__close" @click="close">
          <span class="material-symbols-outlined">close</span>
        </button>
      </div>
      <div class="popover__content" ref="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      const { wrapper, title, content } = this.$refs;
      wrapper.classList.remove("active");
      setTimeout(() => this.$el.classList.remove("active"), 250);
      [title, content].forEach((item) => (item.innerHTML = ""));
      this.scrollLock(false);
    },
  },
};
</script>

<style lang="scss">
.popover {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1070;

  &.active {
    display: block;
  }

  .popover__wrapper {
    opacity: 0;
    display: flex;
    flex-direction: column;
    background: white;
    transition: opacity 0.25s linear;
    height: 100%;

    &.active {
      opacity: 1;
    }

    .popover__content {
      flex-grow: 1;
    }

    .popover__heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 3rem;
      border-bottom: 1px solid var(--border-color);

      .popover__close {
        background: var(--light-blue);
        width: 3rem;
        height: 3rem;
        border-radius: 50%;

        &:hover {
          background: var(--dark-blue);
        }

        .material-symbols-outlined {
          font-size: 2rem;
          color: white;
        }
      }
    }

    .popover__content {
      *:not(iframe) {
        padding: 0 3rem;
      }

      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }
}
</style>
