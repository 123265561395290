import { createRouter, createWebHistory } from "vue-router";
import navItems from "@/composable/globalNavigationItems";
import footerNavItems from "@/composable/footerNavigationItems";

const globalNavigationItems = navItems.map((item) => {
  return handleRouters(item);
});

const footerNavigationItems = footerNavItems.legal.items.map((item) => {
  return handleRouters(item);
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...globalNavigationItems, ...footerNavigationItems],
  scrollBehavior() {
    return {
      top: 0,
    };
  },
});

function handleRouters(item) {
  return {
    path: item.path,
    name: item.name,
    meta: {
      title: item.label,
    },
    props: true,
    component: () => import(`../views/${item.page}.vue`),
  };
}

export default router;
