<template>
  <div class="cookie-consent__box" v-if="show">
    <span class="material-symbols-outlined">cookie</span>
    <div class="cookie-consent__wrapper">
      <h2 class="cookie-consent__title">
        {{ cookieConsent.title[this.lang] }}
      </h2>
      <p class="cookie-consent__content">
        {{ cookieConsent.content[this.lang] }}
      </p>
      <div class="cookie-consent__button-group">
        <button
          class="button cookie-consent__accept-button"
          @click="handleCookies"
        >
          {{ cookieConsent.accept[this.lang] }}
        </button>
        <router-link to="/cookies" class="cookie-consent__link">
          {{ this.ci18n.learnMore[this.lang] }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cookieConsent: {
        title: {
          bg: "Съгласие за бисквитки",
          en: "Cookies Consent",
        },
        content: {
          bg: "Този уебсайт използва бисквитки, за да гарантира, че получавате най-доброто изживяване на нашия уебсайт.",
          en: "This website use cookies to ensure you get the best experience on our website.",
        },
        accept: {
          bg: "Разбирам",
          en: "I Understand",
        },
        learnMore: {
          bg: "Научете повече",
          en: "Learn More",
        },
      },
      show: false,
      cookieBy:
        "bulgartabac-holding-831636680=19472008-bgsf1142-gi62-359sf9306800",
      isCookies: 0,
    };
  },
  methods: {
    handleCookies() {
      const year = 60 * 60 * 24 * 365;
      document.cookie = `${this.cookieBy}; max-age=${year}`;
      this.show = !document.cookie;
    },
  },
  mounted() {
    this.isCookies = document.cookie.indexOf(this.cookieBy);
    this.show = this.isCookies === -1;
  },
};
</script>

<style lang="scss">
.cookie-consent__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 2rem;
  max-width: 21rem;
  border-radius: var(--border-radius);
  text-align: center;
  padding: 1.5rem 1.5rem 2rem;
  background: var(--hell-yellow);
  -webkit-box-shadow: var(--big-shadow);
  -moz-box-shadow: var(--big-shadow);
  box-shadow: var(--big-shadow);
  z-index: 1090;

  .material-symbols-outlined {
    font-size: 4rem;
  }

  .cookie-consent__wrapper {
    margin-top: 0.75rem;

    .cookie-consent__button-group {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;

      @media screen and (min-width: 480px) {
        flex-direction: row;
      }

      .cookie-consent__accept-button {
        padding: 0.5rem 1.5rem;
        border-radius: 1.5rem;
        border: 0.125rem solid var(--dark-blue);
        color: white;
        font-weight: 900;
        font-size: 1.25rem;
        background: var(--light-blue);

        &:hover {
          background: var(--dark-blue);
          border-color: var(--light-blue);
        }
      }

      .cookie-consent__link:not(:hover) {
        text-decoration: none;
      }
    }
  }
}
</style>
